import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  Footer,
  GoalSelector,
  InvestPeriodRangeSelector,
  NumericInput,
  TextInput,
  FoundSelector,
} from "@components";
import {
  Ahorrar,
  Casa,
  FondoEmergencia,
  Jubilacion,
  Otro,
  PagarDeudas,
  Viajar,
} from "@images";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { Return } from "images/Goal";
import { GoalsTexts } from "@data";

interface LayoutProps {
  className?: string;
  styleFooter?: string;
  icon?: string;
  title: string;
  secondTitle?: string;
  sliderValue: number;
  setSliderValue: () => void;
  goalName: string;
  setGoalName: () => void;
  setStepNumber: () => void;
  setGoalType: () => void;
}

export const CreateGoalDesktop: React.FC<LayoutProps> = ({
  className,
  styleFooter,
  icon,
  title,
  secondTitle,
  sliderValue,
  setSliderValue,
  goalName,
  setGoalName,
  setStepNumber,
  setGoalType,
}) => {
  const [isProfile, setProfile] = useState<string>();
  const [imgSelected, setImgSelected] = useState(undefined);
  const [textSelected, setTextSelected] = useState<string>(" ");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const classes = useStyles();
  const GOALS_OPTIONS = [
    "Ahorrar",
    "Pagar deudas",
    "Mejorar mi jubilación",
    "Viajar",
    "Comprar una casa",
    "Fondo de emergencia",
    "Otro",
  ].map((r) => ({
    name: r,
    value: r,
  }));

  const titleProfile =
    isProfile === "Ahorrar"
      ? "Para qué quieres ahorrar?"
      : isProfile === "Pagar deudas"
      ? "Qué deudas quieres pagar?"
      : isProfile === "Mejorar mi jubilación"
      ? "Cuando me retire quiero..."
      : isProfile === "Viajar"
      ? "Dónde quieres ir?"
      : isProfile === "Comprar una casa"
      ? "Dónde quieres vivir?"
      : isProfile === "Otro"
      ? "Sueño con..."
      : "Nombre";

  useEffect(() => {
    setGoalType(isProfile);
    if (isProfile == "Ahorrar") {
      setImgSelected(Ahorrar);
      setTextSelected(GoalsTexts.Ahorrar);
    }
    if (isProfile == "Pagar deudas") {
      setImgSelected(PagarDeudas);
      setTextSelected(GoalsTexts.PagarDeudas);
    }
    if (isProfile == "Mejorar mi jubilación") {
      setImgSelected(Jubilacion);
      setTextSelected(GoalsTexts.Jubilacion);
    }
    if (isProfile == "Viajar") {
      setImgSelected(Viajar);
      setTextSelected(GoalsTexts.Viajar);
    }
    if (isProfile == "Comprar una casa") {
      setImgSelected(Casa);
      setTextSelected(GoalsTexts.Casa);
    }
    if (isProfile == "Fondo de emergencia") {
      setImgSelected(FondoEmergencia);
      setTextSelected(GoalsTexts.Emergencia);
    }
    if (isProfile == "Otro") {
      setImgSelected(Otro);
      setTextSelected(GoalsTexts.Otro);
    }
  }, [isProfile]);

  useEffect(() => {
    if (goalName !== undefined && isProfile) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [goalName, isProfile]);

  useEffect(() => {
    setIsDisabled(true);
  }, []);

  return (
    <>
      <section className={classes.containerData}>
        <div className={classes.children}>
          <section className={classes.container2}>
            <div className={classes.goal}>
              <section>
                <div className={classes.headerLayout2}>
                  <div className={classes.txts}>
                    <span className={classes.title}>
                      {title}
                      {secondTitle}
                    </span>{" "}
                    <span className={classes.subtitle}>Quiero...</span>
                  </div>
                </div>
              </section>
              <GoalSelector
                options={GOALS_OPTIONS}
                value={isProfile}
                onChange={(e) => setProfile(e.target.value)}
              />
            </div>
            <div className={classes.z}>
              <section>
                <div className={classes.headerLayout2}>
                  <div className={classes.txts}>
                    <span className={classes.title}>{titleProfile}</span>{" "}
                    <span className={classes.subtitle}>
                      Ponle nombre a tu alcancía
                    </span>
                  </div>
                </div>
              </section>
              <TextInput
                label="Nombre de tu meta"
                value={goalName}
                onChange={(e) => setGoalName(e.target.value)}
              />
            </div>
            <section className={classes.space}>
              <div className={classes.selectionData}>
                <img src={imgSelected} />
                <div dangerouslySetInnerHTML={{ __html: textSelected }} />
              </div>
            </section>
            <div className={classes.plazo}>
              <section>
                <div className={classes.headerLayout2}>
                  <div className={classes.txts}>
                    <span className={classes.title}>Plazo </span>{" "}
                    <span className={classes.subtitle}>
                      En cuánto tiempo quieres lograr esta meta
                    </span>
                  </div>
                </div>
              </section>
              <InvestPeriodRangeSelector
                className={classes.investPeriod}
                name="goalTerm"
                onChange={(e) => setSliderValue(e.target.value)}
                value={sliderValue}
                label="Quiero lograrlo en:"
              />
            </div>

            <div className={classes.buttons}>
              <ButtonOutlined
                className="outlined"
                onClick={() => navigate(Route.investments + "/?#objectives")}
                text={"Cancelar"}
              />
              <ButtonPrimary
                className="primary"
                text={"Continuar"}
                disabled={isDisabled}
                onClick={() => setStepNumber(1)}
              />
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },
  subContainer: {
    display: "flex",
    marginBottom: "10rem",
  },
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
  },
  children: {},
  headerLayout: {
    display: "grid",
    gap: "2rem",
    alignItems: "center",
    paddingLeft: 106,
    paddingTop: 50,
    width: 200,
    justifyContent: "center",
    "& img": {
      width: 140,
      height: 50,
    },
  },
  txts: {
    paddingTop: "1rem",
    "& span": {
      display: "block",
    },
  },
  numberMissionContainer: {
    position: "relative",
  },
  IconMision: {
    "& img": {
      width: 70,
      height: 70,
    },
  },
  containerData: {
    display: "flex",
    gap: "2rem",
    marginLeft: "3rem",
    width: "100%",
    "& > div": {
      width: "100%",
    },
  },
  return: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    "& img": {
      width: 50,
      height: 50,
    },
  },
  headerLayout2: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    paddingTop: 20,
    marginBottom: "2rem",
    "& img": {
      width: 140,
      height: 140,
    },
  },
  container2: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "58% 48%",
    gridTemplateAreas: "'goal react' 'space plazo' 'found buttons'",
    "& input": {
      width: 400,
    },
  },
  goal: {
    gridArea: "goal",
    "&>div": {
      width: 428,
    },
  },
  z: {
    gridArea: "react",
  },
  plazo: {
    gridArea: "plazo",
  },
  meta: {
    gridArea: "meta",
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: "bold",
    color: "#393e44",
    display: "block",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    display: "block",
    color: theme.palette.text.secondary,
  },
  buttons: {
    gridArea: "buttons",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    paddingTop: "3rem",
    width: 430,
    "& .outlined, .primary": {
      width: 185,
    },
    "& .primary": {
      marginLeft: 20,
    },
  },
  found: {
    gridArea: "found",
  },
  investPeriod: {
    maxWidth: 428,
  },
  foundSelector: {
    width: 428,
  },
  space: {
    width: 420,
    gridArea: "space",
  },
  selectionData: {
    margin: "10px 0 0 0",
    "&>img": {
      width: 120,
      margin: "40px 0 10px 35%",
    },
    "&>div": {
      textAlign: "center",
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: theme.palette.text.secondary,
      fontSize: 16,
      position: "absolute",
      width: "28%",
      maxWidth: 460,
    },
  },
}));
